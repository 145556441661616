import { FC, useEffect, useMemo, useRef } from 'react'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'

import Typography from 'components/Typography'

import styles from './OptionsCard.module.scss'

export interface ISymbol {
  name: string
  dailyChangePercent: number
  icon: string
  profitPercent: number
  chartPoints?: number[]
}
type CardProps = ISymbol & {
  className?: string
  id?: string
}

const canvasWidth = 600
const canvasHeight = 300

const Card: FC<CardProps> = ({
  name,
  dailyChangePercent,
  profitPercent,
  chartPoints,
  icon,
  className,
  id,
}) => {
  const { t } = useTranslation(['main'])
  const canvas = useRef<HTMLCanvasElement>(null)

  const bearTrend = useMemo(() => {
    return dailyChangePercent < 0
  }, [dailyChangePercent])

  const points = useMemo(() => {
    const min = Math.min(...chartPoints)
    const max = Math.max(...chartPoints)
    const scaleFactor = max - min
    const normalizedPoints = chartPoints.map((p) => (p - min) / scaleFactor)

    return normalizedPoints
  }, [chartPoints])
  useEffect(() => {
    const ctx = canvas.current.getContext('2d')
    const step = canvasWidth / points.length + 1
    const [fp, ...other] = points
    ctx.strokeStyle = '#FFA127'
    ctx.lineWidth = 3
    ctx.lineJoin = 'round'
    ctx.lineCap = 'round'
    // draw stroke
    ctx.beginPath()
    ctx.moveTo(0, fp * canvasHeight)
    other.forEach((point, idx) => {
      ctx.lineTo((idx + 1) * step, point * canvasHeight)
    })
    ctx.stroke()
    ctx.closePath()

    // draw filling
    ctx.beginPath()
    ctx.moveTo(0, canvasHeight)
    ctx.lineTo(0, fp * canvasHeight)
    other.forEach((point, idx) => {
      ctx.lineTo((idx + 1) * step, point * canvasHeight)
    })
    ctx.lineTo(canvasWidth, canvasHeight)

    const gradient = ctx.createLinearGradient(0, canvasHeight, 0, 0)
    gradient.addColorStop(0, 'rgba(255, 161, 39, 0)')
    gradient.addColorStop(1, 'rgba(255, 161, 39, 0.25)')
    ctx.fillStyle = gradient
    ctx.fill()
    ctx.closePath()
  }, [canvas, points, bearTrend])

  return (
    <Link
      className={className}
      href="/auth/signup?from=miniGraphs"
      as="/auth/signup"
      id={`main-options-card_${id}-link`}
    >
      <div className={styles.Card}>
        <div className={styles.Symbol}>
          <Image
            src={icon}
            alt={name}
            height={48}
            width={48}
            sizes="48px"
            loading="lazy"
            style={{ width: '48px', height: '48px' }}
          />
          <Typography variant="h5" className={styles.Value}>
            {name}
          </Typography>
        </div>
        <div className={styles.Plot}>
          <canvas
            style={{ width: '100%', minHeight: '152px', aspectRatio: '16 / 7' }}
            width={canvasWidth}
            height={canvasHeight}
            ref={canvas}
          />
        </div>

        <Typography variant="h6" className={styles.VolatilityKey}>
          {t('main:optionsChanges')}
        </Typography>
        <Typography variant="spanNumber" className={styles.Volatility}>
          {dailyChangePercent &&
            (dailyChangePercent > 0 ? (
              <Image
                width={16}
                height={16}
                className={styles.arrowUp}
                src="/images/util/arrow-up-right.svg"
                alt="arrow right up"
              />
            ) : (
              <Image
                width={16}
                height={16}
                className={styles.arrowDown}
                src="/images/util/arrow-down-right.svg"
                alt="arrow right down"
              />
            ))}
          {dailyChangePercent}%
        </Typography>
        <div className={styles.Profit}>
          <Typography variant="h6" className={styles.Key}>
            {t('main:optionsProfit')}
          </Typography>
          <Typography
            variant="spanNumber"
            className={styles.Value}
          >{`${profitPercent}%`}</Typography>
        </div>

        <Typography variant="spanBtn" component="div" className={styles.TradeButton}>
          {t('main:optionsTrade')}
        </Typography>
      </div>
    </Link>
  )
}

export default Card
