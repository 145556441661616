import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import Button from 'components/Button'
import Section from 'components/Section/Section'
import Typography from 'components/Typography'

import styles from './TradeOnPlatform.module.scss'

import LaptopImageLeft from '../../../public/images/platform/laptop-desktop-left.png'
import LaptopImageRight from '../../../public/images/platform/laptop-desktop-right.png'

const Platform = () => {
  const { t } = useTranslation(['main'])
  return (
    <Section backgroundClass={styles.Background}>
      <div className={styles.Grid}>
        <div className={styles.Titles}>
          <Typography variant="h3" className={styles.Title}>
            {t('main:tradeOnPlatformTitle')}
          </Typography>
          <Typography variant="pAccent" className={styles.Subtitle}>
            {t('main:tradeOnPlatformsubTitle')}
          </Typography>
        </div>

        <Button
          id="main-trade_on_platform-trade_now-button"
          href="/auth/signup?from=tradeOnPlatform"
          as="/auth/signup"
          className={styles.Button}
        >
          {t('main:tradeOnPlatformTradeNow')}
        </Button>
      </div>
      <div className={styles.Content}>
        <div className={styles.Desktop}>
          <Image className={styles.laptopLeft} src={LaptopImageLeft} alt="laptop" />
          <Image className={styles.laptopRight} src={LaptopImageRight} alt="laptop" />
        </div>
      </div>
    </Section>
  )
}

export default Platform
