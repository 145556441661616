import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import Link, { LinkProps } from 'next/link'
import styles from './Button.module.scss'

export interface ButtonProps {
  variant?: 'default' | 'circuit'
  className?: string
  id?: string
  href?: LinkProps['href']
  as?: LinkProps['as']
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
}

const Button = (props: PropsWithChildren<ButtonProps>) => {
  const { children, variant = 'default', className, id, href, as, onClick, type, ...rest } = props

  const buttonClassName = classNames(className, styles.button, styles[variant])

  return (
    <>
      {href ? (
        <Link className={buttonClassName} onClick={onClick} id={id} href={href} as={as} {...rest}>
          {children}
        </Link>
      ) : (
        <button onClick={onClick} className={buttonClassName} id={id} type={type} {...rest}>
          {children}
        </button>
      )}
    </>
  )
}

export default Button
