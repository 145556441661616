import { useTranslation } from 'next-i18next'

import Button from 'components/Button'
import Card, { ICardProps } from 'components/Card/Card'
import { IntuitInterfaceIcon, OwnAccIcon, PlanetIcon } from 'components/Images/Icons'
import Section from 'components/Section/Section'
import Typography from 'components/Typography'

import styles from './WhyChoose.module.scss'

const cards: ICardProps[] = [
  {
    icon: <OwnAccIcon />,
    // t('main:whyChooseFreeAccount')
    title: 'main:whyChooseFreeAccount',
    // t('main:whyChooseStayInformed')
    subtitle: 'main:whyChooseStayInformed',
  },
  {
    icon: <IntuitInterfaceIcon />,
    // t('main:whyChooseIntuitiveInterface')
    title: 'main:whyChooseIntuitiveInterface',
    // t('main:whyChooseEnjoyInterface')
    subtitle: 'main:whyChooseEnjoyInterface',
  },
  {
    icon: <PlanetIcon />,
    // t('main:whyChooseFulldaySupport')
    title: 'main:whyChooseFulldaySupport',
    // t('main:whyChooseMultilangSupport')
    subtitle: 'main:whyChooseMultilangSupport',
  },
]

const Pros = () => {
  const { t } = useTranslation(['main'])
  return (
    <Section backgroundClass={styles.Background}>
      <div className={styles.Grid}>
        <Typography variant="h3" className={styles.Header}>
          {t('main:whyChooseTitle')}
        </Typography>

        <div className={styles.Content}>
          {cards.map((card) => {
            return <Card className={styles.Card} key={card.title} {...card} />
          })}
        </div>

        <Button
          href="/auth/signup?from=whyChoose"
          as="/auth/signup"
          className={styles.Button}
          id="main-why_choose-start_trading-button"
        >
          {t(`main:whyChooseGetFreePractice`)}
        </Button>
      </div>
    </Section>
  )
}

export default Pros
