import cn from 'classnames'
import { FC } from 'react'

import { useTranslation } from 'next-i18next'

import Typography from 'components/Typography'
import styles from './Card.module.scss'

export interface ICardProps {
  // eslint-disable-next-line no-undef
  icon: JSX.Element
  title: string
  subtitle: string
  className?: string
}

const Card: FC<ICardProps> = ({ icon, title, subtitle, className }) => {
  const { t } = useTranslation(['main'])

  return (
    <div className={cn(styles.Card, className)}>
      <div className={styles.Icon}>{icon}</div>
      <Typography variant="h4" className={styles.Title}>
        {t(title)}
      </Typography>
      <Typography variant="pMain" className={styles.Subtitle}>
        {t(subtitle)}
      </Typography>
    </div>
  )
}

export default Card
