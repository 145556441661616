import { FC } from 'react'

import { useTranslation } from 'next-i18next'

import Button from 'components/Button'
import { FirstStartUpStep, SecondStartUpStep, ThirdStartUpStep } from 'components/Images/Icons'
import Section from 'components/Section/Section'
import Typography from 'components/Typography'

import styles from './EasySteps.module.scss'

const cardsIcons = [<FirstStartUpStep />, <SecondStartUpStep />, <ThirdStartUpStep />]

const StartSteps: FC = () => {
  const { t } = useTranslation(['main'])
  // t(`main:easyStepsTitle`)
  // t(`main:easyStepsTitle0`)
  // t(`main:easyStepsTitle1`)
  // t(`main:easyStepsTitle2`)

  // t(`main:easyStepsSubtitle0`)
  // t(`main:easyStepsSubtitle1`)
  // t(`main:easyStepsSubtitle2`)
  return (
    <Section backgroundClass={styles.Background} withTopPadding={false}>
      <div className={styles.grid}>
        <Typography variant="h3" className={styles.title}>
          {t('main:easyStepsTitle')}
        </Typography>
        <div className={styles.cards}>
          {cardsIcons.map((icon, idx) => (
            <div key={idx} className={styles.card}>
              <div className={styles.icon}>{icon}</div>
              <Typography variant="h4" className={styles.title}>
                {t(`main:easyStepsTitle${idx}`)}
              </Typography>
              <Typography variant="pMain" className={styles.text}>
                {t(`main:easyStepsSubtitle${idx}`)}
              </Typography>
            </div>
          ))}
        </div>

        <Button
          href="/auth/signup?from=easySteps"
          as="/auth/signup"
          className={styles.Button}
          id="main-start_steps-start_trading-button"
        >
          {t(`main:whyChooseGetFreePractice`)}
        </Button>
      </div>
    </Section>
  )
}

export default StartSteps
