import cn from 'classnames'
import { PlausibleEvent } from 'enums/plausible'
import { FC, Ref, useCallback, useRef, useState } from 'react'
import reviews, { IReview } from 'static/reviews'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import usePlausible from 'utils/hooks/usePlausible'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import Button from 'components/Button'
import Section from 'components/Section/Section'
import Typography from 'components/Typography'

import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/scss/navigation'
import styles from './Reviews.module.scss'

interface ReviewProps {
  item: IReview
  itemRef?: Ref<HTMLDivElement>
  isFullTextShown?: boolean
  setIsFullTextShown?: () => void
  id?: string
}

const Review: FC<ReviewProps> = ({
  item: { i18nKey, text, author, avatarSrc, earning, date },
  isFullTextShown,
  setIsFullTextShown,
  itemRef,
  id,
}) => {
  const { t } = useTranslation(['main', 'reviews'])

  return (
    <div
      ref={itemRef}
      className={cn(styles.review, { [styles.fullTextShown]: isFullTextShown })}
      itemProp="review"
      itemScope
      itemType="http://schema.org/Review"
    >
      <div className={styles.header}>
        <div className={styles.avatar}>
          <Image src={avatarSrc} alt={author} width={128} height={128} />
        </div>
        <Typography variant="h4" itemProp="author" className={styles.name}>
          {author}
        </Typography>
      </div>
      <Typography
        variant="pMain"
        component="div"
        itemProp="reviewBody"
        className={cn(styles.description, { [styles.fullTextShown]: isFullTextShown })}
      >
        {i18nKey ? t(`reviews:${i18nKey}`) : text}
      </Typography>

      {!isFullTextShown && (
        <button
          className={styles.readMoreBtn}
          onClick={() => setIsFullTextShown()}
          id={`main-reviews-read_more_${id}-button`}
        >
          <Typography variant="pMain">{t('main:reviewsReadMore')}</Typography>
        </button>
      )}

      <meta itemProp="datePublished" content={date} />
      <Typography variant="pMain" component="div" className={styles.caption}>
        {`${t('main:reviewsGain')}:`}
        <div className={styles.reviewEarningsValue}>{earning}</div>
      </Typography>
    </div>
  )
}

const Reviews = ({ referrer }) => {
  const { t } = useTranslation(['main'])
  const setEvent = usePlausible()

  const onClick = useCallback(() => {
    setEvent(PlausibleEvent.ClickedleftOwnReview, { referrer })
  }, [referrer])

  const [fullTextShownIndex, setFullTextShownIndex] = useState([])

  const nextRef = useRef<HTMLButtonElement | null>(null)
  const prevRef = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      <Section
        backgroundClass={cn(styles.Background, styles.noOverflow)}
        contentClassName={styles.sectionContent}
      >
        <Typography variant="h3" className={styles.Header}>
          {t('main:reviewsTitle')}
        </Typography>

        <Swiper
          dir="ltr"
          wrapperClass={styles.carouselWrapper}
          navigation={{
            nextEl: nextRef?.current,
            prevEl: prevRef?.current,
          }}
          pagination={{
            type: 'bullets',
            clickable: true,
            bulletClass: styles.dot,
            bulletActiveClass: styles.dotActive,
          }}
          onSlideChange={() => setFullTextShownIndex([])}
          spaceBetween={50}
          slidesPerView={1}
          modules={[Navigation, Pagination]}
          breakpoints={{
            1920: {
              slidesPerView: 3,
              spaceBetween: 85,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 16,
            },
          }}
        >
          {reviews.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Review
                  id={index.toString()}
                  item={item}
                  isFullTextShown={fullTextShownIndex.includes(index)}
                  setIsFullTextShown={() =>
                    setFullTextShownIndex((prevState) => [...prevState, index])
                  }
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <button
          ref={prevRef}
          className={cn('swiper-button-prev', styles.arrow, styles.prev)}
          id="main-reviews-prev-button"
          aria-label="Previous"
        />
        <button
          ref={nextRef}
          className={cn('swiper-button-next', styles.arrow, styles.next)}
          id="main-reviews-next-button"
          aria-label="Next"
        />
      </Section>

      <div className={styles.buttonWrapper}>
        <Button
          id="main-reviews-leave_review-button"
          href="mailto:official@binany.com"
          onClick={() => onClick()}
          className={styles.Button}
          variant="circuit"
        >
          {t(`main:reviewsLeaveReview`)}
        </Button>
      </div>
    </>
  )
}

export default Reviews
