import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react'

import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import ArrowRightLong from 'icons/arrow-right-long.svg'
import styles from './CallToActionButton.module.scss'

export interface CallToActionButtonProps extends ButtonHTMLAttributes<HTMLAnchorElement> {
  className?: string
}

const CallToActionButton = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<CallToActionButtonProps>
>((props, ref) => {
  const { className } = props

  const { t } = useTranslation(['main'])

  const buttonClassName = classNames(className, styles.button)

  return (
    <Link
      id="main-hero-cta-link"
      ref={ref}
      className={buttonClassName}
      href="/auth/signup?from=heroButton"
      as="/auth/signup"
    >
      {t('main:heroTrade')}
      <ArrowRightLong />
    </Link>
  )
})

CallToActionButton.displayName = 'CallToActionButton'

export default CallToActionButton
