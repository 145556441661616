import { useState } from 'react'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import CallToActionButton from 'components/CallToActionButton'
import Section from 'components/Section/Section'
import Typography from 'components/Typography'

import styles from './Hero.module.scss'

import HeroImageBig from '../../../public/images/newPictures/hero-image-big.png'

const Hero = () => {
  const { t } = useTranslation(['main'])
  const [opacity, setOpacity] = useState(0)
  return (
    <Section backgroundClass={styles.HeroBackground}>
      <div className={styles.Grid}>
        <div className={styles.Titles}>
          <Typography variant="h1" className={styles.FrontTitle}>
            {t('main:heroTradeStocks')}
          </Typography>

          <Typography variant="h2" className={styles.Title}>
            {t('main:heroTitle')}
          </Typography>
        </div>
        <Typography variant="pAccent" className={styles.Subtitle}>
          {t('main:heroSubtitle')}
        </Typography>
        <div className={styles.Controls}>
          <div className={styles.Start}>
            <CallToActionButton className={styles.buttonTrade} />
          </div>
        </div>
        <div className={styles.Cover}>
          <Image
            src={HeroImageBig}
            onLoad={() => setOpacity(1)}
            className={styles.phoneImage}
            alt="phone cover"
            style={{
              transition: 'opacity 0.5s',
              opacity,
              position: 'absolute',
              height: '100%',
              width: 'auto',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: '100%',
            }}
            quality={50}
          />
        </div>
      </div>
    </Section>
  )
}

export default Hero
