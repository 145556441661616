import getCurrencies from 'api/currencies'
import getSymbols from 'api/symbols'
import cn from 'classnames'
import { ICurrency } from 'interfaces/ICurrency'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useInfoProviderContext } from 'utils/providers/InfoProvider/InfoProviderContext'
import Card, { ISymbol } from './OptionsCard/OptionsCard'

import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import Section from 'components/Section/Section'
import Typography from 'components/Typography'
import EasySteps from 'sections/EasySteps/EasySteps'
import Reviews from 'sections/Reviews/Reviews'

import styles from './Options.module.scss'

const Options = ({ referrer }) => {
  const { t } = useTranslation(['main'])

  const [cards, setCards] = useState<ISymbol[]>([])

  const [symbolsLength, setSymbolsLength] = useState(0)

  useEffect(() => {
    const apiCall = async () => {
      const res = await getSymbols()
      const {
        data: {
          payload: { symbols },
        },
      }: { data: { payload: { symbols: ISymbol[] } } } = res

      const sorted = symbols.sort((a, b) => {
        if (a.profitPercent > b.profitPercent) return -1
        if (a.profitPercent < b.profitPercent) return 1
        return 0
      })
      const filtered = sorted.filter(
        (el) => el.chartPoints.length > 0 && el.icon && el.dailyChangePercent,
      )
      setSymbolsLength(80)
      setCards((filtered as ISymbol[]).slice(0, 6))
    }

    apiCall()
  }, [setSymbolsLength])

  const [minInvest, setMinInvest] = useState('')
  const [currencyName, setCurrencyName] = useState('')

  const [currencies, setCurrencies] = useState([])
  useEffect(() => {
    const fetchCurrencies = async () => {
      const res = await getCurrencies()
      const items: ICurrency[] = Object.values(res?.data?.payload?.items) || []
      setCurrencies(items)
      // delete symbol?
      setMinInvest(`${items[0].minTradeAmount}`)
    }

    fetchCurrencies()
  }, [setCurrencies])

  const info = useInfoProviderContext()

  useEffect(() => {
    const geoCode = info?.code

    if (geoCode === undefined) return

    const found =
      currencies
        .filter((curr) => {
          return curr.isCanRegistered && curr.minTradeAmount > 0
        })
        .find((curr) => {
          return curr.countries?.toUpperCase().split(',').includes(geoCode?.toUpperCase())
        }) || currencies[0]

    if (found) {
      setMinInvest(`${found?.minTradeAmount}`)
      setCurrencyName(found.symbol)
    }
  }, [info?.code, currencies])

  const optionClassnames = useMemo(
    () =>
      cn(styles.Option, {
        [styles.loaded]: !!symbolsLength && !!minInvest && !!currencyName,
      }),
    [symbolsLength, minInvest, currencyName],
  )

  return (
    <div className={styles.Options_wrapper}>
      <Section
        withTopPadding={false}
        withBottomPadding={false}
        backgroundClass={styles.OptionsBackground}
      >
        <div className={styles.Grid}>
          <div className={optionClassnames}>
            <div className={styles.Title}>{symbolsLength || '-'}</div>
            <Link href="/auth/signup?from=assetsLink" as="/auth/signup">
              <Typography variant="pAccent" className={styles.Subtitle_Link}>
                {t('main:optionsTitle')}
              </Typography>
            </Link>
          </div>
          <div className={optionClassnames}>
            <div className={styles.Title}>15</div>
            <Typography variant="pAccent" className={styles.Subtitle}>
              {t('main:optionsSeconds')}
            </Typography>
          </div>
          <div className={optionClassnames}>
            <div className={styles.Title}>
              <span>{`${minInvest}${currencyName}` || '-'}</span>
            </div>
            <Typography variant="pAccent" className={styles.Subtitle}>
              {t('main:optionsMinInvest') || '-'}
            </Typography>
          </div>
        </div>
      </Section>

      <Reviews referrer={referrer} />

      <EasySteps />

      {!isEmpty(cards) && (
        <Section>
          <div className={styles.OptionsList}>
            <div className={styles.Cards}>
              {cards.map((card, idx) => {
                return <Card {...card} id={idx.toString()} className={styles.card} key={idx} />
              })}
            </div>
          </div>
        </Section>
      )}
    </div>
  )
}

export default Options
