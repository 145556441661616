import React from 'react'

export interface IReview {
  i18nKey?: string
  text?: React.ReactNode
  author: string
  avatarSrc: string
  country?: string
  location?: string
  earning: string
  date: string
}

const reviews: IReview[] = [
  {
    // t(`reviews:review1`)
    i18nKey: `review1`,
    // text: `What sparked off as a simple interest is now the center stage of my life-long investments. I believe I was quite lucky to get an excellent introduction to computers back in High school. Two decades down the line, we got internet connectivity, and there was so much opportunity.`,
    author: 'Sam \nNguyen',
    location: 'Notre Dame',
    country: 'US',
    avatarSrc: '/images/reviews/reviewers/1.png',
    earning: '2750$',
    date: '2023-10-02',
  },
  {
    // t(`reviews:review2`)
    i18nKey: `review2`,
    // text: `For my case, Id say Coronavirus was a blessing in disguise. I lost the job at the tour management company I'd worked for half a decade. On My, where was I going to fend my loved ones from? We'd been eight months into our second pregnancy. Happily, things are different- thanks to Binany and their foreign currencies trading.`,
    author: 'Sam \nSun Yu',
    location: 'Algonquin Park',
    country: 'Canada',
    avatarSrc: '/images/reviews/reviewers/2.png',
    earning: '23600$',
    date: '2022-09-24',
  },
  {
    // t(`reviews:review3`)
    i18nKey: `review3`,
    // text: `Great, I'd say Binany was a heaven-sent opportunity. Although I dropped from my college studies, I never regret the opportunity. I always believed in success and now my college mates envy the life I live. In the past year, trading foreign currencies alone enabled me to marry the love of my life, and we are closing our first year touring the globe.`,
    author: 'Hiroshi \nYamamoto',
    location: 'Toronto',
    country: 'Canada',
    avatarSrc: '/images/reviews/reviewers/3.png',
    earning: '15780$',
    date: '2023-06-09',
  },
  {
    // t(`reviews:review4`)
    i18nKey: `review4`,
    // text: `What started as a college side hassle now features every other day in my life. Although I joined Binany trading Gold, I now do the foreign currencies most. I wish I'd had my formal trading experience with Binany. Overall, very grateful for the resources hosted on the platform.`,
    author: 'Gustav \nParade',
    location: 'Finland Scholarship students',
    country: 'Finland',
    avatarSrc: '/images/reviews/reviewers/5.png',
    earning: '145300$',
    date: '2021-02-22',
  },
  {
    // t(`reviews:review5`)
    i18nKey: `review5`,
    // text: `Well, Binany rolled out at a time when we needed great platforms out there. The good thing is, it has grown, and being in the financial markets, part of our portfolio leverages trading foreign currencies via Binany. We take turns trading and share our returns every Friday.`,
    author: 'Hiroshi \nTakahashi',
    location: 'Melbourne',
    country: 'Australia',
    avatarSrc: '/images/reviews/reviewers/6.png',
    earning: '368030$',
    date: '2021-12-24',
  },
  {
    text: `Binany is a great platform. Our firm can onboard expert freelance traders to manage trades on our behalf. Primarily, I'd say the multiple-language feature has been an excellent helper. We have expert trading foreign currencies every other minute across the globe. That has helped us rake in more ROI and as well able to offset our forms administrative costs- we are almost 90% virtual, and it's been great trading over the last two years on Binany.`,
    author: 'Ukem \nAnlyn-Mandela',
    location: 'Durban',
    country: 'South Africa',
    avatarSrc: '/images/reviews/reviewers/7.png',
    earning: '511000$',
    date: '2020-03-15',
  },
  {
    text: (
      <div>
        Ever since the internet connections came into our neighborhood, my life changed. Firstly, I
        took to advancing my education- currently taking on my PhD in Business Administration. The
        hidden secret is – I earn online from trading foreign currencies on Binany. <br />
        <br />
        I&apos;m able to make decent incomes, pay school fees and give my family a decent life-
        courtesy of Binany.
      </div>
    ),
    author: 'Zahara \nNwachukwu',
    location: 'Karachi City',
    country: 'Pakistan',
    avatarSrc: '/images/reviews/reviewers/8.png',
    earning: '8900$',
    date: '2021-10-17',
  },
  {
    text: `Give me another chance, and I'd go for Binany. Especially, trading foreign currencies has been my most excellent opener. My grandparents never believe it when I tell them that I earn a living online and trading foreign currencies on Binany. But they appreciate the perks and stipends I send every other week to support them. I mean, how else best can I chip in? I love them, and Binany opened a way for me to show it to them in every real sense.`,
    author: 'Kadija \nAdeolu',
    location: 'Hong Kong',
    avatarSrc: '/images/reviews/reviewers/9.png',
    earning: '24300$',
    date: '2020-11-04',
  },
  {
    // text: `If you'd told me about online earning opportunities a decade ago, I'd protest big. Now, it's a reality- courtesy of Binany. Specifically, I trade foreign currencies. It's a whole wide world of opportunities. You require looking at the right places and at the correct times. Binany is a great platform. I didn't study accounting and finance in college- but I made it big. It's plain simple earning with foreign currencies.`,
    // t(`reviews:review9`)
    i18nKey: `review9`,
    author: 'Jinghua \nLiang',
    location: 'Pennsylvania',
    country: 'USA',
    avatarSrc: '/images/reviews/reviewers/10.png',
    earning: '35100$',
    date: '2020-07-27',
  },
  {
    // text: `Well, trading foreign currencies has been a massive game-changer for my life. I was only lucky to make it past college- after we left Somalia barely. I've always had faith in being able to support myself and my family. The little education helped me master online trading, and I've never looked back.`,
    // t(`reviews:review10`)
    i18nKey: `review10`,
    author: 'Burhaan \nAden',
    location: 'Seoul',
    country: 'South Korea',
    avatarSrc: '/images/reviews/reviewers/11.png',
    earning: '17500$',
    date: '2020-01-30',
  },
  {
    // text: `My girlfriend and I have been trading foreign currencies on Binany over the last year now. So far, we've been able to settle all our education loans. I didn't believe it until she scored a great profit, and we went on a weekend away. Overall, we are great fit with Binany; we plan to officially move in together by the end of this year- No worries with incomes.`,
    // t(`reviews:review11`)
    i18nKey: `review11`,
    author: 'Josef \nAntioka',
    location: 'Kingston',
    country: 'Jamaica',
    avatarSrc: '/images/reviews/reviewers/12.png',
    earning: '34400$',
    date: '2020-01-19',
  },
  {
    // text: `I'm a CPA by profession, and I bounced into foreign currencies, I'd say by pure luck. And that's how I landed into Binany. And trading foreign currencies comes first; it's what I do every other day. My profession has on and off surges in contracts, and trading foreign currencies helps me plug into my income gaps courtesy of Binany.`,
    // t(`reviews:review12`)
    i18nKey: `review12`,
    author: 'Salima \nDryksta',
    country: 'Turkey',
    avatarSrc: '/images/reviews/reviewers/13.png',
    earning: '16730$',
    date: '2022-10-24',
  },
  {
    // text: `My life has changed after leaving the army. Three tears down the line – one of the most significant scores is my indoctrination into trading foreign currencies on Binany. Aside from assurance from a good income stream, I plan to open a local bar/café as a side hassle as I think of furthering my education once it's up and rolling.`,
    // t(`reviews:review13`)
    i18nKey: `review13`,
    author: 'Ahmed \nIbrahim',
    location: 'Upper Nile',
    country: 'Egypt',
    avatarSrc: '/images/reviews/reviewers/14.png',
    earning: '19850$',
    date: '2023-06-20',
  },
  {
    // text: `Many times in life, I didn't believe I could make a decent income for myself. Our community and religion shun feminine domination. Although I started as a blogger, my most significant leap came in with trading foreign currencies via Binany. I've never been able to shop around for more. Every other time- I'd go for Binany.`,
    // t(`reviews:review14`)
    i18nKey: `review14`,
    author: 'Yuki \nTanaka',
    location: 'Abidjan',
    country: 'Nigeria',
    avatarSrc: '/images/reviews/reviewers/15.png',
    earning: '10340$',
    date: '2023-08-15',
  },
  {
    // text: `Wow! Sometimes, I feel I should not have immigrated to Canada. Reason? I can earn big online. I trade on foreign currencies on Binany, and it changed my life. But life happens, and all you can do is live your life to the full. But, I make sure to keep colossal investments back in Kenya's East coast- my original homeland.`,
    // t(`reviews:review15`)
    i18nKey: `review15`,
    author: 'Elias \nMwanje',
    location: 'Toronto',
    country: 'Canada',
    avatarSrc: '/images/reviews/reviewers/16.png',
    earning: '24780$',
    date: '2022-03-17',
  },
  {
    // text: `Binany is a great platform. Our form earns great from foreign currencies with it. We are a team of four, and we only got into other assets for diversification purposes. I'd recommend any guys with free time out there to put in some learning and capital to onboard with the foreign currencies trading via Binany.`,
    // t(`reviews:review16`)
    i18nKey: `review16`,
    author: 'Sam \nGilliam',
    location: 'Tel Aviv',
    country: 'Israel',
    avatarSrc: '/images/reviews/reviewers/17.png',
    earning: '285390$',
    date: '2023-09-12',
  },
  {
    // text: `Anyone can trade foreign currencies and make good money. In my case, it took me one and a half months to be confident enough. Yes, I was skeptical, and I did my homework on the demos quite well. I trade foreign currencies with great ease, and I've never regretted any moment of it.`,
    // t(`reviews:review17`)
    i18nKey: `review17`,
    author: 'Austin \nBlake',
    location: 'Sierra Leone',
    avatarSrc: '/images/reviews/reviewers/18.png',
    earning: '1440$',
    date: '2022-01-03',
  },
  {
    // text: `Wow! Binany has grown. I joined when we were around a thousand users, and now there are 2Million and growing. What I like about Binany is the ability to make a decent side hustle. It's been paying all my rent and entertainment budgets since last year!`,
    // t(`reviews:review18`)
    i18nKey: `review18`,
    author: 'Adam \nDobson',
    country: 'Philippines',
    avatarSrc: '/images/reviews/reviewers/19.png',
    earning: '48590$',
    date: '2023-06-06',
  },
  {
    // text: `I love Binany. Apart from trading on Binany assets, I love Gold, and their site shows up the spot prices via a scroll bar. It helps me with my swing trades for Gold. Overall, I earn a large portion of my income from Binany.`,
    // t(`reviews:review19`)
    i18nKey: `review19`,
    author: 'John \nDeMathews',
    country: 'Asia Pacific',
    avatarSrc: '/images/reviews/reviewers/20.png',
    earning: '38660$',
    date: '2021-04-14',
  },
]

export default reviews
