import cn from 'classnames'
import { FC } from 'react'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import { Bkash, Easypaisa, Nagad, PayTm, PhonePe, UPI } from 'components/Images/BillingSystems'
import Section from 'components/Section/Section'
import Typography from 'components/Typography'

import styles from './PaymentSystems.module.scss'

const JazzCash = () => {
  return (
    <>
      <Image
        src="/images/JazzCash.png"
        width={182}
        height={101}
        alt="Jazzcash payment system"
        loading="lazy"
      />
    </>
  )
}

const Mapesa = () => {
  return (
    <>
      <Image
        src="/images/mapesa.png"
        width={211}
        height={106}
        alt="Jazzcash payment system"
        loading="lazy"
      />
    </>
  )
}

const billingSystems = [
  <PayTm />,
  <Easypaisa />,
  <PhonePe />,
  <UPI />,
  <Bkash />,
  <Nagad />,
  <JazzCash />,
  <Mapesa />,
]

interface IProps {
  className?: string
}
const PaymentSystems: FC<IProps> = ({ className }) => {
  const { t } = useTranslation(['main'])

  return (
    <Section backgroundClass={cn(styles.BillingBackground, className)}>
      <div className={styles.Grid}>
        <Typography variant="h3" className={styles.Header}>
          {t(`main:paymentSystemsTitle`)}
        </Typography>

        <div className={styles.Content}>
          {billingSystems.map((item, idx) => {
            return (
              <div key={idx} dir="ltr" className={styles.System}>
                {item}
              </div>
            )
          })}
        </div>
      </div>
    </Section>
  )
}

export default PaymentSystems
